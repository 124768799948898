import classes from './Project.module.scss'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import ProjectButton from '../layout/ProjectButton'
import useProjectsNext from '../../hooks/useProjectsNext'
import ProgressiveImage from 'react-progressive-image'

import tinyImage1 from '../../images/teamme/tiny-teamme-1.jpg'
import tinyImage2 from '../../images/teamme/tiny-teamme-2.jpg'
import tinyImage3 from '../../images/teamme/tiny-teamme-3.jpg'
import tinyImage4 from '../../images/teamme/tiny-teamme-4.jpg'
import tinyImage5 from '../../images/teamme/tiny-teamme-5.jpg'
import tinyImage6 from '../../images/teamme/tiny-teamme-6.jpg'
import tinyImage7 from '../../images/teamme/tiny-teamme-7.jpg'
import tinyImage8 from '../../images/teamme/tiny-teamme-8.jpg'
import tinyImage9 from '../../images/teamme/tiny-teamme-9.jpg'

import image1 from '../../images/teamme/teamme-1.png'
import image2 from '../../images/teamme/teamme-2.png'
import image3 from '../../images/teamme/teamme-3.png'
import image4 from '../../images/teamme/teamme-4.png'
import image5 from '../../images/teamme/teamme-5.png'
import image6 from '../../images/teamme/teamme-6.png'
import image7 from '../../images/teamme/teamme-7.png'
import image8 from '../../images/teamme/teamme-8.png'
import image9 from '../../images/teamme/teamme-9.png'

const TeammeProject = () => {
  const location = useLocation()
  const projects = useSelector(state => state.app.projects);
  const currentProjectId = location.pathname.split('projects/')[1]
  const currentProject = projects.find(project => project.id === currentProjectId)
  const [nextProjectId, isNextButtonVisible] = useProjectsNext()

  return (
    <section className={classes.project}>
      <ProgressiveImage src={image1} placeholder={tinyImage1}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text1}</p>

      <div className={classes['project-list-wrapper']}>
        <p className={`${classes['project-list-title']} text-sang`}>{currentProject.listText}</p>
        <ul className={classes['project-list']}>
          {currentProject.listItems.map((item, i) =>
            <li key={`${currentProject.listText.length}${i}`} className={`${classes['project-list__item']} text-sang`}>{item}</li>
          )}
        </ul>
      </div>

      <p className={`${classes['project__text']} text-sang`}>{currentProject.text2}</p>
      <ProgressiveImage src={image2} placeholder={tinyImage2}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text3}</p>
      <ProgressiveImage src={image3} placeholder={tinyImage3}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text4}</p>

      <div className={classes['project-list-wrapper']}>
        <p className={`${classes['project-list-title']} text-sang`}>{currentProject.listText2}</p>
        <ul className={classes['project-list']}>
          {currentProject.listItems2.map((item, i) =>
            <li key={`${currentProject.listText.length}${i}`} className={`${classes['project-list__item']} text-sang`}>{item}</li>
          )}
        </ul>
      </div>

      <ProgressiveImage src={image4} placeholder={tinyImage4}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>

      <div className={`${classes['project-3partsBlock']} flex`}>
        <ProgressiveImage src={image5} placeholder={tinyImage5}>
          {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={`${classes['project__image']} ${classes['project-3partsBlock__image']} aline-self-baseline`} src={src} alt="" />}
        </ProgressiveImage>
        <div className={`${classes['project-3partsBlock-group']} flex-column`}>
          <ProgressiveImage src={image6} placeholder={tinyImage6}>
            {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
          </ProgressiveImage>
          <p className={`${classes['project__text']} text-sang`}>{currentProject.text5}</p>
        </div>
      </div>

      <ProgressiveImage src={image7} placeholder={tinyImage7}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <ProgressiveImage src={image8} placeholder={tinyImage8}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text6}</p>

      <ProgressiveImage src={image9} placeholder={tinyImage9}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} ${classes['project__text_last']} text-sang`}>{currentProject.text7}
        &nbsp;
        <a href="https://www.figma.com/file/x5cGwhu5XHxLIgKihige1g/Projector-%E2%80%94-Team-Me?node-id=355%3A3932" target='_blank' rel="noreferrer" className={`${classes['project__link']} text-sang`}>here</a>
        .
      </p>

      {isNextButtonVisible &&
        <div className={classes['next-btn-visibility-handler']}>
          <ProjectButton nextProjectId={nextProjectId} />
        </div>
      }

    </section>
  )
}

export default TeammeProject
