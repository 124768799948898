import { Route, Switch } from "react-router";
import Project from "./components/pages/Project";
import Projects from "./components/projects/Projects";
import About from "./components/pages/About";
import Header from "./components/layout/Header";
import Layout from "./components/layout/Layout";
import Menu from "./components/layout/Menu";
import HelpUkraine from "./components/layout/HelpUkraine";
import { Fragment } from "react";
import ScrollToTop from "./components/layout/ScrollToTop";
// import { Helmet } from "react-helmet-async";
// import AnnaImage from "./images/Ann.png";
// import ogImage from "./images/metatag_thumbnail_second-website.png";

const routes = [
  {
    path: "/",
    exact: true,
    sidebar: () => (
      <div className="flex-column">
        <Header />
        <HelpUkraine />
      </div>
    ),
    main: () => <Menu />,
  },
  {
    exact: true,
    path: "/projects",
    sidebar: () => (
      <div className="flex-column">
        <Header />
        <HelpUkraine />
        <Projects />
      </div>
    ),
    main: () => <Menu />,
  },
  {
    exact: true,
    path: "/projects/:projectId",
    sidebar: () => null,
    main: () => (
      <Fragment>
        <Project />
        <Menu />
      </Fragment>
    ),
  },
  {
    path: "/about",
    sidebar: () => (
      <div className="flex-column">
        <Header />
        <HelpUkraine />
        <About />
      </div>
    ),
    main: () => <Menu />,
  },
  {
    path: "*",
    sidebar: () => <Header />,
    main: () => null,
  },
];

function App() {
  return (
    <Fragment>
      <Layout>
        <ScrollToTop>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.sidebar />}
              />
            ))}
          </Switch>

          <Switch>
            {/* <div className="flex-column"> */}
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
            {/* </div> */}
          </Switch>
        </ScrollToTop>
      </Layout>
    </Fragment>
  );
}

export default App;
