import { Link } from "react-router-dom";
import classes from "./ProjectCard.module.scss";
import ProgressiveImage from "react-progressive-image";

function ProjectCard(props) {
  return (
    <div className={classes["flip-card"]}>
      <div
        className={classes["flip-card-inner"]}
        style={
          props.active === props.project.title
            ? { transform: `rotateY(180deg)` }
            : { transform: `unset` }
        }
      >
        <div
          className={classes["flip-card-front"]}
          onClick={() => props.onCardFrontClickHandler(props.project.title)}
        >
          <figure className="figure">
            <ProgressiveImage
              src={props.project.image}
              placeholder={props.project.tinyImage}
            >
              {(src, loading) => (
                <img
                  style={{ filter: loading ? "grayscale(0.5) blur(10px)" : "" }}
                  loading="lazy"
                  className={`${classes["flip-card-img"]}`}
                  src={src}
                  alt={props.project.alt}
                />
              )}
            </ProgressiveImage>

            {/* <img loading="lazy"
            src={props.project.image}
            alt={props.project.alt}
            className={`${classes['flip-card-img']}`}
          /> */}
          </figure>
        </div>
        <div
          className={classes["flip-card-back"]}
          onClick={() => props.onCardBackClickHandler(props.project.title)}
        >
          <div>
            <h2 className={classes["flip-card-title"]}>
              {props.project.title}
            </h2>
            <p className={`${classes["flip-card-text"]} text-medium_height`}>
              {props.project.inrtoduction}
            </p>
          </div>

          {props.project.id === "previous-projects" ? (
            <a
              href="https://annayushch2020.web.app/"
              target="_blank"
              rel="noreferrer"
              className={classes["flip-card-btn"]}
            >
              View website
            </a>
          ) : (
            <Link
              className={classes["flip-card-btn"]}
              to={`projects/${props.project.id}`}
            >
              View project
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
