import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import classes from "./Header.module.scss";

const Header = () => {
  const [isAboutPage, setIsAboutPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    location.pathname === "/about"
      ? setIsAboutPage(true)
      : setIsAboutPage(false);
  }, [location]);

  return (
    <header
      className={`${classes.header} ${isAboutPage && classes["header_about"]}`}
    >
      <p className={`text-sang ${classes["header-text"]}`}>
        I am a Kyiv-based designer with 5 years of experience. I design digital
        products, interfaces, and mobile apps for clients in IoT, medicine, and
        tech. Currently product designer at{" "}
        <span
          className={classes["icon-decipad"]}
          role="img"
          aria-label="decipad"
        ></span>{" "}
        <a
          href="https://www.decipad.com"
          target="_blank"
          rel="noreferrer"
          className={classes["header-text__link"]}
        >
          Decipad
        </a>
        .
      </p>
    </header>
  );
};

export default Header;
