import classes from './Project.module.scss'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import ProgressiveImage from 'react-progressive-image'

import tinyImage1 from '../../images/mdd-lp/tiny-mdd-lp-1.jpg'
import tinyImage2 from '../../images/mdd-lp/tiny-mdd-lp-2.jpg'
import tinyImage3 from '../../images/mdd-lp/tiny-mdd-lp-3.jpg'
import tinyImage4 from '../../images/mdd-lp/tiny-mdd-lp-4.jpg'
import tinyImage5 from '../../images/mdd-lp/tiny-mdd-lp-5.jpg'
import tinyImage6 from '../../images/mdd-lp/tiny-mdd-lp-6.jpg'
import tinyImage7 from '../../images/mdd-lp/tiny-mdd-lp-7.jpg'
import tinyImage8 from '../../images/mdd-lp/tiny-mdd-lp-8.jpg'
import tinyImage9 from '../../images/mdd-lp/tiny-mdd-lp-9.jpg'
import tinyImage10 from '../../images/mdd-lp/tiny-mdd-lp-10.jpg'

import image1 from '../../images/mdd-lp/mdd-lp-1.png'
import image2 from '../../images/mdd-lp/mdd-lp-2.png'
import image3 from '../../images/mdd-lp/mdd-lp-3.png'
import image4 from '../../images/mdd-lp/mdd-lp-4.png'
import image5 from '../../images/mdd-lp/mdd-lp-5.png'
import image6 from '../../images/mdd-lp/mdd-lp-6.png'
import image7 from '../../images/mdd-lp/mdd-lp-7.png'
import image8 from '../../images/mdd-lp/mdd-lp-8.png'
import image9 from '../../images/mdd-lp/mdd-lp-9.png'
import image10 from '../../images/mdd-lp/mdd-lp-10.png'
import useProjectsNext from '../../hooks/useProjectsNext'
import ProjectButton from '../layout/ProjectButton'

const MddLpProject = () => {
  const location = useLocation()
  const projects = useSelector(state => state.app.projects);
  const currentProjectId = location.pathname.split('projects/')[1]
  const currentProject = projects.find(project => project.id === currentProjectId)
  const [nextProjectId, isNextButtonVisible] = useProjectsNext()

  return (
    <section className={classes.project}>
      <div className={`${classes['project-2partsBlock']} flex`}>
        <ProgressiveImage src={image1} placeholder={tinyImage1}>
          {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={`${classes['project__image']} ${classes['project-2partsBlock__image']}`} src={src} alt="" />}
        </ProgressiveImage>
        <ProgressiveImage src={image2} placeholder={tinyImage2}>
          {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={`${classes['project__image']} ${classes['project-2partsBlock__image']}`} src={src} alt="" />}
        </ProgressiveImage>
      </div>

      <div className={`${classes['project-3partsBlock']} flex`}>
        <ProgressiveImage src={image3} placeholder={tinyImage3}>
          {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={`${classes['project__image']} ${classes['project-3partsBlock__image']}`} src={src} alt="" />}
        </ProgressiveImage>
        <div className={`${classes['project-3partsBlock-group']} flex-column`}>
          <ProgressiveImage src={image4} placeholder={tinyImage4}>
            {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
          </ProgressiveImage>
          <ProgressiveImage src={image5} placeholder={tinyImage5}>
            {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
          </ProgressiveImage>
        </div>
      </div>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text1}</p>

      <ProgressiveImage src={image6} placeholder={tinyImage6}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <ProgressiveImage src={image7} placeholder={tinyImage7}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <ProgressiveImage src={image8} placeholder={tinyImage8}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>

      <div className={`${classes['project-3partsBlock']} flex`}>

        <ProgressiveImage src={image9} placeholder={tinyImage9}>
          {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={`${classes['project__image']} ${classes['project-3partsBlock__image']}`} src={src} alt="" />}
        </ProgressiveImage>
        <div className={`${classes['project-3partsBlock-group']} flex-column`}>
          <ProgressiveImage src={image10} placeholder={tinyImage10}>
            {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
          </ProgressiveImage>
          <p className={`${classes['project__text']} text-sang`}>{currentProject.disclaimer}</p>
        </div>
      </div>

      {isNextButtonVisible &&
        <div className={classes['next-btn-visibility-handler']}>
          <ProjectButton nextProjectId={nextProjectId} />
        </div>
      }

    </section>
  )
}

export default MddLpProject
