import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import MddDesktopProject from '../projects/MddDesktopProject'
import MddMobileProject from '../projects/MddMobileProject'
import MddLpProject from '../projects/MddLpProject'
import ChargingProject from '../projects/ChargingProject'
import PreviousProject from '../projects/PreviousProject'
import TeammeProject from '../projects/TeammeProject'
import { Fragment } from 'react'

const Project = () => {
  const location = useLocation()
  const projects = useSelector(state => state.app.projects);
  const currentProjectId = location.pathname.split('projects/')[1]
  const currentProject = projects.find(project => project.id === currentProjectId)

  const renderSwitch = (param) => {
    switch (param) {
      case 'mdd-mobile':
        return <MddMobileProject project={currentProject} />;
      case 'charging':
        return <ChargingProject project={currentProject} />;
      case 'previous-projects':
        return <PreviousProject project={currentProject} />;
      case 'teamme':
        return <TeammeProject project={currentProject} />;
      case 'mdd-desktop':
        return <MddDesktopProject project={currentProject} />;
      case 'mdd-lp':
        return <MddLpProject project={currentProject} />;
      default:
        return <MddDesktopProject project={currentProject} />;
    }
  }

  return (
    <Fragment>
      {renderSwitch(currentProjectId)}
    </Fragment>
  )
}

export default Project
