import classes from "./About.module.scss";
import ReactGA from "react-ga";
ReactGA.pageview(window.location.pathname);

const About = () => {
  return (
    <section className={classes.about}>
      <p className={`text-sang ${classes["about-text"]}`}>
        I used to work with some companies and startups. Before that, I worked
        in an agency for a variety of interface and marketing design for product
        and service companies.
      </p>
      <p className={`text-sang ${classes["about-text"]}`}>
        Passionate about creating tools and experiences that improve user life.
        As both a creative type and a perfectionist with systems in mind, I’m
        open to new ideas and different ways of figuring things out.
      </p>
      <p className={`text-sang ${classes["about-text"]}`}>
        I enjoy working with people who appreciate high quality — ideally in a
        product design role at a mission-driven company. I'm interested in
        professional tools, emerging technologies, healthcare products, tools
        for education, and self-improvement. In my free time, I like to read and
        play games on PS. I also love martial arts, and go on hike trips as
        often as I can.
      </p>
    </section>
  );
};

export default About;
