import classes from "./Projects.module.scss";
import ProjectCard from "./ProjectCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Projects = () => {
  const [activeCard, setActiveCard] = useState(null);
  const [projects, setProjects] = useState([]);
  let stateProjects = useSelector((state) => state.app.projects);
  const projectsMobileOrder = [
    "mdd-mobile",
    "teamme",
    "charging",
    "mdd-desktop",
    "mdd-lp",
    "previous-projects",
  ];

  useEffect(() => {
    if (window.innerWidth <= 812) {
      stateProjects = projectsMobileOrder.map((i) =>
        stateProjects.find((j) => j.id === i)
      );
      setProjects(stateProjects);
    } else {
      setProjects(stateProjects);
    }
  }, []);

  const cardFrontClickHandler = (cardName) => {
    setActiveCard(cardName);
  };

  const cardBackClickHandler = (cardName) => {
    setActiveCard(null);
  };

  return (
    <section className={classes.projects}>
      <div className={`${classes["projects-grid"]}`}>
        {projects.map((project, i) => (
          <ProjectCard
            key={i}
            project={project}
            active={activeCard}
            onCardFrontClickHandler={cardFrontClickHandler}
            onCardBackClickHandler={cardBackClickHandler}
          />
        ))}
      </div>
    </section>
  );
};

export default Projects;
