import classes from './Project.module.scss'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import ProgressiveImage from 'react-progressive-image'

import tinyImage1 from '../../images/mdd-desk/tiny-mdd-desk-1.jpg'
import tinyImage2 from '../../images/mdd-desk/tiny-mdd-desk-2.jpg'
import tinyImage3 from '../../images/mdd-desk/tiny-mdd-desk-3.jpg'
import tinyImage4 from '../../images/mdd-desk/tiny-mdd-desk-4.jpg'
import tinyImage5 from '../../images/mdd-desk/tiny-mdd-desk-5.jpg'
import tinyImage6 from '../../images/mdd-desk/tiny-mdd-desk-6.jpg'
import tinyImage7 from '../../images/mdd-desk/tiny-mdd-desk-7.jpg'
import tinyImage8 from '../../images/mdd-desk/tiny-mdd-desk-8.jpg'
import tinyImage9 from '../../images/mdd-desk/tiny-mdd-desk-9.jpg'
import tinyImage10 from '../../images/mdd-desk/tiny-mdd-desk-10.jpg'

import image1 from '../../images/mdd-desk/mdd-desk-1.png'
import image2 from '../../images/mdd-desk/mdd-desk-2.png'
import image3 from '../../images/mdd-desk/mdd-desk-3.png'
import image4 from '../../images/mdd-desk/mdd-desk-4.png'
import image5 from '../../images/mdd-desk/mdd-desk-5.png'
import image6 from '../../images/mdd-desk/mdd-desk-6.png'
import image7 from '../../images/mdd-desk/mdd-desk-7.png'
import image8 from '../../images/mdd-desk/mdd-desk-8.png'
import image9 from '../../images/mdd-desk/mdd-desk-9.png'
import image10 from '../../images/mdd-desk/mdd-desk-10.png'

import useProjectsNext from '../../hooks/useProjectsNext'
import ProjectButton from '../layout/ProjectButton'

const MddDesktopProject = () => {
  const location = useLocation()
  const projects = useSelector(state => state.app.projects);
  const currentProjectId = location.pathname.split('projects/')[1]
  const currentProject = projects.find(project => project.id === currentProjectId)
  const [nextProjectId, isNextButtonVisible] = useProjectsNext()

  return (
    <section className={classes.project}>
      <ProgressiveImage src={image1} placeholder={tinyImage1}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text1}</p>
      <ProgressiveImage src={image2} placeholder={tinyImage2}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text2}</p>
      <ProgressiveImage src={image3} placeholder={tinyImage3}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text3}</p>
      <ProgressiveImage src={image4} placeholder={tinyImage4}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text4}</p>

      <ProgressiveImage src={image5} placeholder={tinyImage5}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>

      <ProgressiveImage src={image6} placeholder={tinyImage6}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text5}</p>
      <ProgressiveImage src={image7} placeholder={tinyImage7}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text6}</p>
      <ProgressiveImage src={image8} placeholder={tinyImage8}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text7}</p>
      <ProgressiveImage src={image9} placeholder={tinyImage9}>
        {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={classes['project__image']} src={src} alt="" />}
      </ProgressiveImage>
      <p className={`${classes['project__text']} text-sang`}>{currentProject.text8}</p>

      <div className={`${classes['project-2partsBlock']} ${classes['project-2partsBlock_mb']} flex`}>
        <ProgressiveImage src={image10} placeholder={tinyImage10}>
          {(src, loading) => <img style={{ filter: loading ? 'grayscale(0.5) blur(10px)' : '' }} loading="lazy" className={`${classes['project__image']} ${classes['project-2partsBlock__image']} aline-self-baseline`} src={src} alt="" />}
        </ProgressiveImage>
        <p className={`${classes['project__image']} ${classes['project-2partsBlock__image']} text-sang`}>{currentProject.text9}</p>
      </div>

      <p className={`${classes['project__text']} ${classes['project__text_last']} text-sang`}>{currentProject.disclaimer}</p>

      {isNextButtonVisible &&
        <div className={classes['next-btn-visibility-handler']}>
          <ProjectButton nextProjectId={nextProjectId} />
        </div>
      }

    </section>
  )
}

export default MddDesktopProject
