import tinyProjectMddMobile from "../images/projects/tiny-project-mdd-mobile.jpg";
import tinyProjectTeamme from "../images/projects/tiny-project-mobile-course.jpg";
import tinyProjectCharging from "../images/projects/tiny-project-charging.jpg";
import tinyProjectMddDesktop from "../images/projects/tiny-project-mdd-desktop.jpg";
import tinyProjectPrevious from "../images/projects/tiny-project-previous.jpg";
import tinyProjectMddLp from "../images/projects/tiny-project-rightcore.jpg";

import projectMddMobile from "../images/projects/project-mdd-mobile.png";
import projectTeamme from "../images/projects/project-mobile-course.png";
import projectCharging from "../images/projects/project-charging.png";
import projectMddDesktop from "../images/projects/project-mdd-desktop.png";
import projectPrevious from "../images/projects/project-previous.png";
import projectMddLp from "../images/projects/project-rightcore.png";

const initialProjects = [
	{
		id: "mdd-mobile",
		image: projectMddMobile,
		tinyImage: tinyProjectMddMobile,
		imageAlt:
			"Mobile application for a health-tech startup that digitizes healthcare practices",
		title: "Healthtech startup 1/3",
		inrtoduction:
			"Mobile application for a health-tech startup that digitizes healthcare practices, building telehealth services to push the healthcare experience and access to it forward.",
		text1: "I was hired by a company, which has been working on digitizing health care practices for several years in collaboration with doctors and specialists from the health care sector. I led design independently for this project while working with a stakeholder, development lead, and development squad.",
		text2: "The MDD team approached me with a number of problems and goals: to help improve the overall user experience across both patient and health care provider apps, add a lot of new functionality, and to establish a new visual direction for the brand and their tools.",
		text3: "This product enables an individual doctor, as well as a clinic, to digitalize their practice, where their patients can receive help, make an appointment and receive treatment.",
		text4: "In addition to fast communication between doctor and patient + the ability to schedule a convenient appointment, users have access to medical history, personal information, previous visits, test results, and other medical indicators and health conditions.",
		text5: "Doctor get detailed information and reports about his practise, including sales, treatments and appointments. In addition, it is possible to see real-time and recent activity, gain insight into patients, and analyze practise performance.",
		text6: "The patient questionnaire we created and the new interface of the service, will allow the doctor to quickly examine the patient's medical history, especially in emergency cases and for patients with extensive medical history.",
		disclaimer:
			"Disclaimer: Certain aspects of the design work presented here have been modified in order to protect company privacy. Any data displayed is fictitious. This showcase does not represent the prodcuts from client in it’s entirety.",
		year: "2020-2021",
	},
	{
		id: "charging",
		image: projectCharging,
		tinyImage: tinyProjectCharging,
		imageAlt: "Application for recharging electric cars",
		title: "Application for recharging electric cars",
		inrtoduction:
			"Desktop management application for a company that deals with mobile charging stations.",
		text1: "This project was for a company that is a globally active producer of charging solutions for electric vehicles. Project has shipped in October 2020 and it is under NDA. Therefore, I will present here some images without a detailed description of the case.",
		text2: "I worked on a project in partnership with a Ukrainian software development company, specifically with a team of PM and devs. My task was to design an electric car charging stations management platform for 4 user roles. For this, we have created user stories, CJM, and described the specification of each role in detail. Based on these workings I made high fidelity prototype, prototype tests, and design. It was transferred to the devs for development along with all the necessary components in the library.",
		disclaimer:
			"Dsclaimer: Certain aspects of the design work presented here have een modified in order to protect company privacy. Any data displayed is fictitious. This showcase does not represent the prodcuts from client in it’s entirety.",
		year: "2020",
	},
	{
		id: "previous-projects",
		image: projectPrevious,
		tinyImage: tinyProjectPrevious,
		imageAlt: "Some pictures from 2018 to 2020",
		title: "Some pictures from 2018 to 2020",
		inrtoduction: "My previous site, where you can find more of my work.",
		text1: "",
		disclaimer: "",
		year: "2018-2020",
	},
	{
		id: "teamme",
		image: projectTeamme,
		tinyImage: tinyProjectTeamme,
		imageAlt:
			"My final project for a client in the productivity domain as part of the Projector Mobile Interfaces Advanced course",
		title: "Onboarding journey",
		inrtoduction:
			"My final project for a client in the productivity domain as part of the Projector Mobile Interfaces Advanced course.",
		text1: "The current app does not have any type of onboarding to assist the user. Onboarding is an important part of any digital product because it shows the value that the product is providing to its users/customers and also helps them to learn-as-they-go for a seamless experience.",
		listText: "My tasks were:",
		listItems: [
			"Design onboarding and the first session for new users who have installed the app;",
			"Get them as close as possible to getting their first value and a-ha moment;",
			"Make decisions and propose ideas should impact the key metrics of the app.",
		],
		text2: "In preparation for design, I identified the problems. To formulate them in the language of users, I used the JBTD framework and described Job Stories:",
		text3: "Additionally formulated hypotheses about onboarding:",
		text4: "Based on the work done, I offered different ideas on how to solve the initial problem that a client described during the briefing.",
		listText2: "In addition to the ideas, I was answering questions:",
		listItems2: [
			"What problem does the idea solve?",
			"What will it affect in the user world?",
			"What will it affect in the business world?",
			"What are the possible pros and cons of the solution?",
		],
		text5: "Since the time for the project on the course was limited, I chose 2 ideas to refine (hypotheses №2 and №3), and added the missing edge scenarios.",
		text6: "For the client's convenience, I put the information together in a presentation, adding a \"What's next\" block with tips on onboarding: what should we do with this design next? What to improve?",
		text7: "More information and the project itself you can find",
		disclaimer:
			"Disclaimer: Certain aspects of the design work presented here have been modified in order to protect company privacy. Any data displayed is fictitious. This showcase does not represent the prodcuts from client in it’s entirety.",
		year: "2021",
	},
	{
		id: "mdd-desktop",
		image: projectMddDesktop,
		tinyImage: tinyProjectMddDesktop,
		imageAlt:
			"Desktop application for a health-tech startup that digitizes healthcare practices",
		title: "Healthtech startup 2/3",
		inrtoduction:
			"Desktop application for a health-tech startup that digitizes healthcare practices, building telehealth services to push the healthcare experience and access to it forward.",
		text1: "I was hired by a company, which has been working on digitizing health care practices for several years in collaboration with doctors and specialists from the health care sector. I led design independently for this project while working with a stakeholder, development lead, and development squad.",
		text2: "The MDD team approached me with a number of problems and goals: to help improve the overall user experience across both patient and health care provider apps, add a lot of new functionality, and to establish a new visual direction for the brand and their tools.",
		text3: "In addition to fast communication between doctor and patient + the ability to schedule a convenient appointment, users have access to medical history, personal information, previous visits, test results, and other medical indicators and health conditions.",
		text4: "This product enables an individual doctor, as well as a clinic, to digitalize their practice, where their patients can receive help, make an appointment and receive treatment.",
		text5: "40+ screens were created, with described logic for all roles (patient, doctor, owner) to customize the application to the individual needs of the user.",
		text6: "Doctor get detailed information and reports about his practise, including sales, treatments and appointments. In addition, it is possible to see real-time and recent activity, gain insight into patients, and analyze practise performance.",
		text7: "One of the important tasks was to create onboarding to introduce new users to our product and help them get the most out of it. Since the app contains a lot of functionality, we have created multi-step pointer messages in a product tour to walk new users through the steps to get started. Additionally we made custom bot to give the ability easily create a conversational flow to capture a new user’s intent and then send them on the relevant tour directly inside the conversation.",
		text8: "The patient questionnaire we created and the new interface of the service, will allow the doctor to quickly examine the patient's medical history, especially in emergency cases and for patients with extensive medical history.",
		text9: "A collaborative design system was created for the project with documented components that can be understood and used by both designers and developers. Focus was to design the structure and variation + style sections of the documentation.",
		disclaimer:
			"Disclaimer: Certain aspects of the design work presented here have been modified in order to protect company privacy. Any data displayed is fictitious. This showcase does not represent the prodcuts from client in it’s entirety.",
		year: "2020-2021",
	},
	{
		id: "mdd-lp",
		image: projectMddLp,
		tinyImage: tinyProjectMddLp,
		imageAlt:
			"Landing page template for a health-tech startup that digitizes healthcare practices",
		title: "Healthtech startup 3/3",
		inrtoduction:
			"Landing page template for a health-tech startup that digitizes healthcare practices, building telehealth services to push the healthcare experience and access to it forward.",
		text1: "This is part of the MDD product. Here shown some of the work on templates. As a user of the product, the doctor builds his practice site to simplify the delivery of his services. The user has a choice of different blocks from which he can build his own site for any size practice, or he can choose from ready-made templates.",
		text2: "Disclaimer: Certain aspects of the design work presented here have been modified in order to protect company privacy. Any data displayed is fictitious. This showcase does not represent the prodcuts from client in it’s entirety.",
		disclaimer:
			"Disclaimer: Certain aspects of the design work presented here have been modified in order to protect company privacy. Any data displayed is fictitious. This showcase does not represent the prodcuts from client in it’s entirety.",
		year: "2020-2021",
	},
];

export default initialProjects;
