import classes from './Project.module.scss'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

const PreviousProject = () => {
  const location = useLocation()
  const projects = useSelector(state => state.app.projects);
  const currentProjectId = location.pathname.split('projects/')[1]
  const currentProject = projects.find(project => project.id === currentProjectId)

  return (
    <section>
      <h1>{currentProject.title}</h1>
    </section>
  )
}

export default PreviousProject
