import { useState } from "react";
import classes from "../projects/ProjectCard.module.scss";

function HelpUkraine(props) {
  const [activeCard, setActiveCard] = useState(false);

  const cardClickHandler = (cardName) => {
    setActiveCard(!activeCard);
  };

  return (
    <div
      className={`${classes["flip-card"]} ${classes["flip-card__helpukraine"]}`}
    >
      <div
        className={`${classes["flip-card-inner"]} ${classes["flip-card-inner__helpukraine"]}`}
        style={
          activeCard ? { transform: `rotateY(180deg)` } : { transform: `unset` }
        }
      >
        <div className={classes["flip-card-front"]} onClick={cardClickHandler}>
          <div className={`${classes["flip-card-gradient"]} flex-center`}>
            <h2
              className={`${classes["flip-card-helpukraine-title"]} text-medium-20`}
            >
              Support Ukraine and help us stop the war
            </h2>
          </div>
        </div>
        <div
          className={`${classes["flip-card-back"]} ${classes["flip-card-back__helpukraine"]}`}
          onClick={cardClickHandler}
        >
          <h2 className={classes["flip-card-title"]}>
            Make donations to security organizations
          </h2>
          <div>
            <a
              href="https://savelife.in.ua/donate/"
              target="_blank"
              rel="noreferrer"
              className={`${classes["flip-card-btn"]} ${classes["flip-card-btn__helpukraine"]}`}
            >
              Come Back Alive
            </a>

            <a
              href="https://u24.gov.ua/"
              target="_blank"
              rel="noreferrer"
              className={`${classes["flip-card-btn"]} ${classes["flip-card-btn__helpukraine"]}`}
            >
              UNITED 24
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpUkraine;
